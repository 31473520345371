import styled, { keyframes, css } from "styled-components";

export const RightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  height: fit-content;
  gap: 46px;
  padding: 64px 64px;
  background: rgba(235, 240, 242, 1);

  justify-content: space-between;
  @media (max-width: 926px) {
    gap: 0px;
    justify-content: space-between;
    padding-right: 20px;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 64px 16px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const slideUp = keyframes`
  
  0%{
    opacity: 0;
    transform: translateY(100%)
  }

  100%{
    opacity: 1;
    transform: translateY(0)
  }
`;

interface props {
  isVisible: boolean;
  delay: string;
}

export const Block = styled.div<props>`
  display: flex;
  flex-direction: column;
  max-width: 292px;
  height: 184px;
  position: relative;
  opacity: 0;
  align-items: flex-start;

  @media (max-width: 550px) {
    flex-direction: row;
    max-width: 100%;
  }

  div {
    flex-direction: column;
    @media (max-width: 500px) {
      width: 283px;
    }
  }

  ${({ isVisible, delay }) =>
    isVisible &&
    css`
      animation: ${slideUp} 0.8s ease-in-out forwards;
      animation-delay: ${delay};
    `}

  img {
    height: 55px;
    margin-right: 20px;

    @media (max-width: 550px) {
      width: 32px;
      margin-right: 15px;
    }
  }

  @media (max-width: 926px) {
    width: 35%;
    margin: 0 20px 100px;
  }

  @media (max-width: 800px) {
    width: 45%;
    margin: 0;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
    color: #133244;
    @media (max-width: 550px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #657a85;

    @media (max-width: 550px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;
