import { Address, FooterLinksContainer } from "../styles";

const FooterAddress = () => {
  return (
    <FooterLinksContainer>
      <p>Our Address</p>
      <Address>
        12 Russell Parade,
        <br />
        London, <br />
        NW11 9NN
      </Address>
    </FooterLinksContainer>
  );
};

export default FooterAddress;
