import styled, { keyframes, css } from "styled-components";

export const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  background: #ebf0f2;
`;

export const LeftContainer = styled.div`
  max-width: 421px;
  margin: 97px 0 160px 64px;

  @media (max-width: 1200px) {
    max-width: 300px;
  }

  @media (max-width: 1100px) {
    width: calc(100% - 128px);
    max-width: 100%;
  }

  @media (max-width: 800px) {
    margin: 70px 16px 0;
  }
`;

export const RightContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 57%;
  margin-top: 105px;
  height: fit-content;
  gap: 46px;

  @media (max-width: 1100px) {
    width: calc(100% - 128px);
    margin: 0 auto 40px;
    justify-content: space-between;
  }
  @media (max-width: 926px) {
    gap: 0px;
    justify-content: space-between;
    padding-right: 20px;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 0 16px;
    margin-top: 40px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-end;
  }
`;

export const Header = styled.h1`
  font-family: "Anton";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 52px;
  letter-spacing: 0.06em;
  color: #133244;
  margin-bottom: 16px;
`;

export const Smaller = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #657a85;
  margin-bottom: 20px;
`;

export const ButtonCont = styled.div`
  margin-top: 40px;
  @media (max-width: 800px) {
    margin-left: 16px;
    margin-bottom: 50px;
  }
`;

const slideUp = keyframes`
  
  0%{
    opacity: 0;
    transform: translateY(100%)
  }

  100%{
    opacity: 1;
    transform: translateY(0)
  }
`;

interface props {
  isVisible: boolean;
  delay: string;
}

export const Block = styled.div<props>`
  display: flex;
  flex-direction: column;
  max-width: 292px;
  height: 184px;
  position: relative;
  opacity: 0;
  align-items: flex-start;

  @media (max-width: 650px) {
    height: fit-content;
    margin-bottom: 20px;
  }

  @media (max-width: 550px) {
    max-width: 100%;
    flex-direction: row;
  }

  div {
    flex-direction: column;
    @media (max-width: 500px) {
      width: 283px;
    }
  }

  ${({ isVisible, delay }) =>
    isVisible &&
    css`
      animation: ${slideUp} 0.8s ease-in-out forwards;
      animation-delay: ${delay};
    `}

  img {
    /*  width: 44px; */
    height: 55px;
    margin-right: 20px;

    @media (max-width: 550px) {
      width: 32px;
      margin-right: 15px;
    }
  }

  @media (max-width: 926px) {
    width: 35%;
    margin: 0 20px 100px;
  }

  @media (max-width: 800px) {
    width: 45%;
    margin: 0 0 20px;
  }

  @media (max-width: 500px) {
    width: 100%;
  }

  h1 {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    margin-bottom: 15px;
    color: #133244;
    @media (max-width: 550px) {
      font-size: 20px;
      line-height: 28px;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #657a85;

    @media (max-width: 550px) {
      font-size: 14px;
      line-height: 22px;
    }
  }
`;
