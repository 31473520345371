import trophy from "./thumbsv2.svg";
import fingerprint from "./fingerprintv2.svg";
import bulb from "./lightbulbv2.svg";
import target from "./nthv2.svg";

const values = {
  trophy,
  fingerprint,
  bulb,
  target,
};

export default values;
